<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 22.955 30.649"
  >
    <path
      :style="iconStyle"
      d="M86.325,0H65.166a.9.9,0,0,0-.9.9V27.106a.9.9,0,0,0,.263.635l2.645,2.645a.9.9,0,0,0,1.27,0l2.01-2.01,2.01,2.01a.9.9,0,0,0,1.27,0l2.01-2.01,2.01,2.01a.9.9,0,0,0,1.27,0l2.01-2.01,2.01,2.01a.9.9,0,0,0,1.27,0l2.645-2.645a.9.9,0,0,0,.263-.635V.9A.9.9,0,0,0,86.325,0ZM74.847,6.745V5.707a.9.9,0,1,1,1.8,0V6.732h1.026a.9.9,0,0,1,0,1.8H75.1a1.026,1.026,0,0,0,0,2.051h1.282a2.821,2.821,0,0,1,.257,5.631v1.038a.9.9,0,0,1-1.8,0V16.222H73.822a.9.9,0,0,1,0-1.8h2.565a1.026,1.026,0,1,0,0-2.051H75.1a2.821,2.821,0,0,1-.257-5.631Zm5.707,16.21H70.936a.9.9,0,1,1,0-1.8h9.618a.9.9,0,0,1,0,1.8Z"
      transform="translate(-64.268)"
    />
  </svg>
</template>

<script>
// TODO: This icon is not used anywhere
export default {
  props: {
    height: {
      type: String,
      default: '19.17',
    },
    width: {
      type: String,
      default: '14.35',
    },
    color: {
      type: String,
      default: 'text-color',
    },
  },
  computed: {
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.invoice {
  fill: #3516d6;
}
</style>
